<template lang="html">
  <div id="content">
    <div id="list-content" class="vx-card p-6 vs-con-loading__container">
      <vs-prompt
        :title="$t('actions.exportData')"
        class="export-options"
        @cancel="clearFields"
        @accept="exportToExcel"
        :accept-text="$t('actions.export')"
        :cancel-text="$t('actions.cancel')"
        @close="clearFields"
        :active.sync="activePrompt"
      >
        <vs-input
          v-model="fileName"
          :placeholder="$t('actions.enterFilename')"
          class="w-full"
        />
        <v-select
          v-model="selectedFormat"
          :clearable="false"
          :options="formats"
          class="my-4"
        />
      </vs-prompt>

      <vs-row>
        <div class="vx-col sm:w-1/6 md:w-1/5 lg:w-1/6 w-full">
          <label class="vs-input--label"
            ><strong>{{ $t("transaction.machine") }}</strong></label
          >
          <v-select
            v-model="selectedMachineId"
            :clearable="false"
            :options="machineOptions"
            :searchable="true"
            :reduce="x => x.id"
            label="serial_number"
            :disabled="!machineOptions.length"
            :filter-by="machineFilter"
          >
            <template v-slot:option="option">
              <span>{{ option.serial_number }}</span
              ><br />
              <small
                ><strong>{{ getModelName(option.type) }}</strong></small
              >
            </template>
            <span slot="no-options">{{ $t("navbar.noResults") }}</span>
          </v-select>
        </div>
        <div class="vx-col lg:w-1/2 w-full ml-4">
          <div>
          <label class="vs-input--label">
            <strong>{{ $t("transaction.period.title") }}</strong>
          </label>
          </div>
          <flat-pickr :config="configFromdateTimePicker" v-model="fromDate" :placeholder="$t('transaction.period.from')" @on-change="onFromChange" />
          <span> - </span>
          <flat-pickr :config="configTodateTimePicker" v-model="toDate" :placeholder="$t('transaction.period.to')" @on-change="onToChange" :disabled="!fromDate"/>
        </div>
      </vs-row>

      <vs-row>
        <vs-col vs-type="flex" vs-w="6">
          <vs-button
            class="mb-4 md:mb-0 mt-5"
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-rotate-cw"
            @click="loadData()"
          ></vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-w="6">
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :label-placeholder="$t('navbar.search')"
            />
            <vs-button class="mb-4 md:mb-0 mt-5" @click="activePrompt = true">{{
              $t("actions.export")
            }}</vs-button>
          </div>
        </vs-col>
      </vs-row>

      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table text-small"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import i18n from "@/i18n/i18n";
import { getAgGridTranslation } from "@/utils/utils";
import vSelect from "vue-select";
import moment from "moment";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';

const locales = {
  'pt_BR' : Portuguese,
  'es': Spanish,
  'en': null
};

const getModelName = function (type) {
  switch (type) {
    case 0:
      return "STRIKE";
    case 1:
      return "ONE";
    case 2:
      return "Catamoeda";
    case 3:
      return "FIT";
  }
};

export default {
  name: "TransactionList",
  components: {
    AgGridVue,
    vSelect,
    flatPickr
  },
  props: ["type"],
  data: () => ({
    data: [],
    machineOptions: [],
    selectedMachineId: null,
    currencies: [
      "BRL",
      "USD",
      "AUD",
      "CLP",
      "EUR",
      "SOL",
      "PYG",
      "ARS",
      "PKR",
      "JOD",
      "KES",
      "DOP",
      "HNL",
      "AED"
    ],

    //Export2Excel
    activePrompt: false,
    fileName: "",
    formats: ["xlsx", "csv", "txt"],
    cellAutoWidth: true,
    selectedFormat: "xlsx",
    headerTitle: [
      "Id",
      i18n.t("transaction.user"),
      i18n.t("common.date"),
      i18n.t("transaction.operationType"),
      i18n.t("transaction.currency"),
      i18n.t("transaction.value")
    ],
    headerVal: ["id", "user", "date", "typeStr", "currencyCode", "value"],
    searchQuery: "",
    daily: true,
    data_local: {},

    //DatePicker
    fromDate: null,
    toDate: null,
    configFromdateTimePicker: {
      minDate: null,
      maxDate: null,
      dateFormat: 'd/m/Y',
      locale: locales[localStorage.getItem('language')]
    },
    configTodateTimePicker: {
      minDate: null,
      dateFormat: 'd/m/Y',
      locale: locales[localStorage.getItem('language')]
    },

    // AgGrid
    gridOptions: {
      localeTextFunc: function(key, defaultValue) {
        return getAgGridTranslation(key, defaultValue);
      },
      suppressCellSelection: true
    },
    maxPageNumbers: 7,
    totalIntPages: 1,
    currenIntPage: 1,
    gridApi: null,
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMenu: true
    },
    columnDefs: [
      {
        headerName: i18n.t("common.id"),
        field: "id",
        filter: true
      },
      /*
      {
        headerName: i18n.t("transaction.company"),
        field: "company",
        filter: true,
      },
      
      {
        headerName: i18n.t("transaction.operation"),
        field: "flowStr",
        filter: true
      },
      */
      {
        headerName: i18n.t("transaction.user"),
        field: "user",
        filter: true,
        width: 300
      },
      {
        headerName: i18n.t("transaction.login"),
        field: "login",
        filter: true,
        width: 150
      },
      {
        headerName: i18n.t("common.date"),
        field: "date",
        filter: true,
        width: 300
      },
      /*
      {
        headerName: i18n.t("transaction.machine"),
        field: "machine",
        filter: true,
      },*/
      {
        headerName: i18n.t("transaction.operationType"),
        field: "typeStr",
        filter: true
      },
      {
        headerName: i18n.t("transaction.currency"),
        field: "currencyCode",
        filter: true
      },
      {
        headerName: i18n.t("transaction.value"),
        field: "value",
        filter: true
      },
      /*
      {
        headerName: i18n.t("transaction.balance"),
        field: "balance",
        filter: true
      }
      */
    ]
  }),
  watch: {
    selectedMachineId: function() {
      this.loadData();
    },
    currentPage: function() {
      this.loadData();
    }
  },
  methods: {
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, 'minDate', dateStr);
      this.currentPage = 1;
      this.loadData();
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr);
      this.currentPage = 1;
      this.loadData();
    },
    loadData() {
      let self = this;

      let date_start = null;
      let date_end = null; 
      if (this.fromDate && this.toDate) {
        date_start = moment(this.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00");
        date_end = moment(this.toDate, "DD/MM/YYYY").format("YYYY-MM-DD 23:59:59");
      }
      this.$vs.loading({
        container: "#list-content",
        scale: 0.5
      });
      this.$store
        .dispatch("httpRequest", {
          method: "post",
          data: {
            filter: {
              machineIds: [self.selectedMachineId],
              flow: self.type === "deposit" ? 0 : 1,
              date_start,
              date_end,
              page: self.currentPage,
            }
          },
          url: `transaction/all`
        })
        .then(response => {
          if (response.error) {
            this.$vs.notify({
              title: i18n.t("dialog.error"),
              text: response.error,
              color: "danger"
            });
          } else {
            let data = response.data.results;
            self.totalIntPages = response.data.pages;


            data.forEach(transaction => {
              transaction.date = moment
                .utc(transaction.date)
                .local()
                .format("DD/MM/YYYY HH:mm:ss");
              transaction.currencyCode = this.currencies[
                transaction.currency - 1
              ];
              transaction.value = transaction.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: transaction.currencyCode,
                minimumFractionDigits: 2
              });

              /*
              if (transaction.balance) {
                transaction.balance = transaction.balance.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: transaction.currencyCode,
                    minimumFractionDigits: 2
                  }
                );
              }
              */

              transaction.user =
                transaction.details.USER || transaction.details.OPERATOR_NAME;
              transaction.login = transaction.details.LOGIN;

              if (transaction.flow !== 1) {
                transaction.typeStr =
                  transaction.type === 2
                    ? this.getManualDepositType(transaction)
                    : this.getDepositType(transaction);
              } else {
                transaction.typeStr = !transaction.user
                  ? i18n.t("transaction.collectTypes.forced")
                  : i18n.t("transaction.collectTypes.default");
              }

              transaction.flowStr =
                transaction.flow === 0
                  ? i18n.t("transaction.depositFlows.deposit")
                  : i18n.t("transaction.depositFlows.withdrawal");
            });

            self.data = data;
            self.dataRaw = data;
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        })
        .finally(() => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
        });
    },
    getManualDepositType(transaction) {
      const manualType = transaction.isCheck ? i18n.t("transaction.check") : i18n.t("transaction.money");
      return i18n.t("transaction.depositTypes.manual") + ` | ${manualType}`;
    },
    getDepositType(transaction) {
      switch (transaction.operation) {
        case 1:
          return i18n.t("transaction.depositTypes.productBonus");
        case 2:
          return i18n.t("transaction.depositTypes.cashBonus");
        case 3:
          return i18n.t("transaction.depositTypes.gift");
        case 4:
          return i18n.t("transaction.depositTypes.cellPurchase");
        case 5:
          return i18n.t("transaction.depositTypes.internal");
        case 6:
        case 31:
          return i18n.t("transaction.depositTypes.promotion");
        case 7:
          return i18n.t("transaction.depositTypes.auto");
        case 30:
          return i18n.t("transaction.depositTypes.googlePlayCredits");
        default:
          return "";
      }
    },
    loadMachines() {
      var self = this;

      this.$vs.loading({
        container: "#list-content",
        scale: 0.5
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "machine/all" })
        .then(data => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          self.machineOptions = data.filter(x => x.active);

          if (!self.data_local.id && self.machineOptions.length) {
            self.selectedMachineId = self.machineOptions[0].id;
          }
        })
        .catch(error => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    getModelName(type) {
      return getModelName(type);
    },
    machineFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return label.toLowerCase().indexOf(temp) > -1 || 
      getModelName(option.type).toLowerCase().indexOf(temp) > -1
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then(excel => {
        const list = [];
        this.gridOptions.api
          .getModel()
          .rootNode.childrenAfterFilter.forEach(x => {
            list.push(x.data);
          });

        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        });
        this.clearFields();
      });
    },
    clearFields() {
      (this.fileName = ""), (this.selectedFormat = "xlsx");
    }
  },
  computed: {
    paginationPageSize() {
      if(this.toDate && this.fromDate){
        return 100;
      }

      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.totalIntPages){
        return this.totalIntPages;
      }
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        return this.currenIntPage
      },
      set(val) {
        this.currenIntPage = val;
      }
    }
  },
  mounted() {
    if (this.$route.params.machine) {
      this.data_local = this.$route.params.machine;
      this.selectedMachineId = this.data_local.id;
    }
    this.gridApi = this.gridOptions.api;
    this.gridOptions.api.sizeColumnsToFit();
    this.loadMachines();
  }
};
</script>
<style scoped></style>
